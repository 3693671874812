.portfolio-item {
  .video-fit-fullscreen {
		position: absolute;
		top: 0;
		left: 0;
		display: block;
		// cursor: none;
		width: 100%;
		height: 100%;
		margin: 0;
		padding: 0;

		video {
			z-index: -1;
			opacity: 1;
			position: absolute;
			top: 0;
			width: 100%;
			height: 100%;
			// float: left;
			object-fit: cover;
			@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
			/* IE10+ CSS styles go here */
				height: auto;
			}
			@supports (-ms-ime-align:auto) {
			    height: auto;
			}
      
    }
    .video-controls-container {
      display: none;
    }
    // cursor: pointer;
    &:hover {
      .video-controls-container {
        .icon {
          opacity: 1;
        }
      }
    }
    @include breakpoint(small down) {
      // scroll-snap-type: y mandatory;
      
    }
    // .video-controls-container {
    //   display: none;
    //   position: absolute;
    //   z-index: 2;
    //   top: 100%;
    //   left: 100%;
    //   width: 50px;
    //   height: 50px;
    //   margin-top: -80px;
    //   margin-left: -80px;
    //   a.icon {
    //     opacity: .1;
    //     transition: opacity .2s ease-out;
    //     text-decoration: none;
    //     display: block;
    //     color: #fff;
    //     width: 100%;
    //     height: 100%;
    //     background-image: url(/frontend/images/sound-off.svg);
    //     background-size: 100% 100%;
    //     background-repeat: no-repeat;
    //     &.muted {
    //       background-image: url(/frontend/images/sound-on.svg);
    //     }
    //   }
      
    //   &.home {
    //     display: none;
    //   }
    //   @include breakpoint(small down) {
    //     width: 44px;
    //     height: 44px;
    //     margin-top: -50px;
    //     margin-left: -50px; 
    //     a {
    //       font-size: 2.2rem;
    //     }
    //   }
  
    // }
    .overlay-transparent-image {
      opacity: 1;
      min-height: 100%;
    }
  }
  @include breakpoint(small down) {
    // scroll-snap-type: y mandatory;
    .project-description-container {
      max-height: 110px !important;
      overflow: hidden;
      &.expand {
        max-height: none !important;
     }
    }
  }
  h1 {
    text-transform: uppercase;
    font-size: 2rem;
    @include breakpoint(small down) {
      font-size: 1rem;
      margin: 0 !important;
    }
  }
  h3 {
    font-size: 1rem;
  }
  p {
    font-size: .9rem;
    line-height: 1.1rem;
  }
  .row {
    &.full-width {
        @include breakpoint(xxxlarge) {
          width: 60rem;
        }
    }
  }
  .portfolio-item-zoom {
    position: relative;
    display: block;
    width: 100%;
    height: 100%;
    .zoom {
      position: absolute;
      top: 0;
      height: 100%;
      left: auto;
      margin: auto;
      right: 0;
      z-index: 150;
      display: block;
      width: auto;
      transform-origin: right top;
      transition: all .4s ease-out;
      transform: scale(1);
    }
  }
  .section {
    @include breakpoint(xxlarge up) {
      // scroll-snap-align: start;
    }
    overflow: hidden;
    position: relative;
    img:not(.overlay-transparent-image) {
      visibility: hidden;
      
      @include breakpoint(small down) {
        visibility: visible;
      }
    }
    figure  {
      img {
        visibility: visible !important;
      }
    }
    &.full-height {
      .owl-carousel {
        img {
          visibility: visible;
          height: 100vh;
          object-fit: cover;
          @include breakpoint(small down) {
            height: auto !important;
          }
        }
        
      }
    }
    .floating-element {
      padding: auto;
      text-align: center;

      img {
        max-width: 73%;
        max-height: auto;
        visibility: visible !important;
        vertical-align: middle;
        display: inline;
        line-height: 1.2;
      }
    }
    .columns {
      position: relative;
    }
    @include breakpoint(small up) {
      .owl-carousel {
        .owl-item {
          img {
            visibility: visible;
          }
        }
      }
      .pr-40 {
        padding-right: 0.5rem !important;
      }
      
    }
    @include breakpoint(small down) {
      .row {
        // background-image: none !important;
        .columns:first-child {
          p {
            padding-top: 10px;
          }
        }
      }
      &:nth-child(2) {
        padding-top: 0 !important;
      }
    }
  }
  .half-height {
    height: 50%;
    padding-right: 100px !important;
  }
  .full-height {
    padding-right: 100px !important;
  }
  .normal-height {
    padding-right: 100px !important;
  }
  .section {
    padding-right: 100px !important;
  }
  .plus {
    display: block;
    width: 40px;
    height: 40px;
    position: absolute;
    right: 15px;
    top: 15px;
    // background-color: $main-color;
    transition: $normal-animation;
    &:after {
      content: "";
      width: 100%;
      height: 1px;
      background-color: #fff;
      display: block;
      top: 50%;
      position: absolute;
      // margin-top: -1 px;
      left: 0;
      transition: $normal-animation;
    }
    &:before {
      transition: $normal-animation;
      content: "";
      width: 1px;
      height: 100%;
      background-color: #fff;
      display: block;
      top: 0;
      position: absolute;
      // margin-left: -1px;
      left: 50%;
    }

  }
  a.image-link {
    overflow: hidden;
    position: absolute;
    height: 100%;
    // background: red;
    width: 100%;
    display: block;
    &:hover {
      .plus {
        &:before, &:after {
          background-color: $main-color;
        }
      }
    }
    @include breakpoint(small down) { 
      position: static;
    }
  }
  @include breakpoint(medium down) {
    .full-height, .half-height, .normal-height, .section {
      padding-right: 0px !important;
    }
  }
  .project-description {
    transition: $normal-animation;
    @include breakpoint(small down) { 
      h1 {
        margin-bottom: 10px !important;
      }
      display: block;

    }
  }
  .read-more {
    position: relative;
    z-index: 1;
    width: 100%;
    background-color: $white;
    height: 40px;
    bottom: 0px;
    left: 0;
    display: block;
    line-height: 40px;
    font-family: $titles-font;
    padding-left: 10px;
    color: $text-color !important;
    text-decoration: none; 
    font-style: normal;
    font-size: .8rem;
    &.less {
      background-color: $text-color;
      color: $white !important;
      .plus {
        &:after, &:before {
          background-color: $white;
        }
        &:before {
          display: none;
        }
      }
    }
    .plus {
      width: 20px;
      height: 20px;
      top: 50%;
      transform: translateY(-50%);
      &:after, &:before {

        background-color: $text-color;
      }
    }
  }
}

.nav-projects {
  font-size: .9rem;
  .nav-button {
    display: inline-block;
    position: relative;
    text-decoration: none;
    color: $text-color;
    box-sizing: content-box;
    min-height: 42px;

    &:after {
      content: "";
      left: 10px;
      top: 0;
      position: absolute;
      background-image: url('/frontend/images/project-previous-arrow.svg');
      width: 70px;
      height: 42px;
      background-repeat: no-repeat;
      transition: all .2s ease-out;
    }
    .btn-label {
      transition: $normal-animation;
      font-family: $titles-font;
      display: block;
    }
    &:hover {
      .btn-label {
        color: $main-color;
        
      }
      &:after {
        left: 0;
      }
      &.next { 

        &:after {
          left: auto;
          right: 20px;
        }
      }
    }
    &.next {
      // text-align: left;
      &:after {
        background-image: url('/frontend/images/project-next-arrow.svg');
        left: auto;
        right: 30px;
      }
    }
    .arrow-link {
      top: 0px;
      position: absolute;
      transition: all .2s ease-out;

      img {
        width: 70px;
        visibility: visible;
      }
      .line {
        fill:none;stroke:#000000;stroke-miterlimit:10;
        transition: all .2s ease-out;
      }
      .polyline {
        fill:none;stroke:#000000;stroke-width:1.0811;stroke-miterlimit:10;
        transition: all .2s ease-out;
      }
    }
    @include breakpoint(small down) {
      text-align: left !important;
      padding-right: 90px;
      position: relative;
      line-height: 1rem;
      .columns {
        position: relative;
        top: 50%;
        -webkit-transform: translateY(-50%);
        transform: translateY(-50%);
      }
    
    }
  }
  .back-to-projects {
    font-family: $titles-font;
    text-decoration: none;
    color: $text-color;
    display: inline;
    height: auto;
    // line-height: 42px;

    &:hover {
      color: $main-color;
    }
    @include breakpoint(small down) {
      display: block;
      color: $white;
      background-color: $main-color;
      height: 100%;
      width: 100%;
      text-align: right;
      min-height: 60px;
      line-height: 60px;
      padding-right: 10px;
      position: relative;
      &:hover {
        color: $white;
        background-color: $main-color;
      }
      &:before {
        content: "";
        position: absolute;
        left: 10px;
        top: 50%;
        background-image: url(/frontend/images/arrow-left-white-big.svg);
        background-repeat: no-repeat;
        width: 40%;
        height: 50px;
        transform: translateY(-25%);
      }
    }
  }
  
}
@include breakpoint(medium down) {
  .nav-projects-container {
    margin: 80px 20px !important;
    
  }
}
@include breakpoint(small down) {
  .nav-projects-container {
    margin: 0px !important;
    .nav-projects {
      .columns {
        padding: 0px !important;

      }
      .nav-button {
          text-align: left !important;
          // padding-left: 10px !important;
          float: none !important;
          // width: 100%;
          padding-left: 10px !important;
          display: block;
          margin: 0;
          height: 60px;
          // line-height: 60px;
          font-size: .7rem;
          .columns {
            
            text-align: left !important;
          }
          .btn-label {
            display: inline-block;
          }
          &:after {
            left: auto !important;
            right: 20px !important;
            top: 7px !important;
            display: block;
          }
        }
      
    }
  }
}
