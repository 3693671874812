$portfolio-col-height: 90vh;
#fullpage {
  @include breakpoint(medium down) {
  section.section {
      padding-right: 0px !important;
      min-height: calc(100vh - 60px);
      position: relative;
      .project-highlight-list, .bottom-links {
        padding-right: 40px !important;
      }
      
    }

  }
  @media screen and (max-width: 39.9375em) {
    .small-12.b-left {
      border-right-width: 1px;
    }
    .small-12.b-right {
      border-left-width: 1px;
    }
  }
}
.container.home-punch {
  @include breakpoint(medium up) {
    
    h1 {

      p {
        line-height: 2.3rem;
        margin-bottom: 0;
      }
    }
  }
  h3 {
    font-family: $content-font;
    font-style: normal;
    font-weight: normal;
    strong {
      font-family: $titles-font;
      text-transform: uppercase;
      -webkit-text-stroke: 1px black;
      color: transparent;
      font-style: normal;
      font-weight: normal;
      transition: all .2s ease-out;
    }
    a {
      text-decoration: none;
      color: $text-color;
      &:hover {
        strong {
          -webkit-text-stroke: 1px $blue;
        }
      }
      @include breakpoint(medium up) {
        p:last-child(){
          font-size: 1.1rem;
        }
      }
    }
  }
  @include breakpoint(small down) {
    // h1 {
    //   font-size: 1.2rem;
    // }
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 90%;
    h3 {
      font-size: 0.8rem;
    }

    .middle {
      margin-top: -60px;
    }
    .mb-70 {
      margin-bottom: 30px !important;
    }
  }
}
.section {
  transition: all .8s ease-out;
  background-color: transparent;
  .bottom {
    position: absolute;
    bottom: 0;
    width: 100%;
    @include breakpoint(small down) {
      bottom: 0;
      padding-right: 0px !important;
    }
  }
  .bottom-links {
    a {
      text-decoration: none;
      font-family: $links;
      color: #000000;
      font-size: .9rem;
      transition: color .2s ease-out;
      &:hover {
        color: $blue;
      }
    }
    @include breakpoint(small down) {
      padding-left: 1rem !important;
      a {
        .arrow-link {
          width: 100%;
          height: 100%;
          display: block;
        }
      }
    }
  }
  &.categories {
    .bottom {
      .bottom-links {
        a {
          color: #ffffff;
          &:hover {
            color: $blue;
          }
        }
        .arrow-link {
          .line {
            stroke:#ffffff;
          }
          .polyline {
            stroke:#ffffff;
          }
          &:hover {
            .polyline, .line {
              stroke:$blue;
            }
          }
        }
      }
    }
    .container {
      padding-bottom: 30px;
    }
    @include breakpoint(small down) {
      .marquee-container {
        top: 50% !important;
        transform: translateY(-50%) !important;
        margin-top: -30px !important;
        position: absolute;
        .marquee {
          height: 54px !important;
        }
      }
    }
    @media only screen 
    and (device-width : 414px) 
    and (device-height : 896px) 
    and (-webkit-device-pixel-ratio : 3) { 
      .marquee-container {
        background-color: red;
      }
    }
  }
  &.menu-opened {
    transform: translateX(-25px);
  }

  &.project-highlight {
    .fp-scrollable {
      overflow: visible !important;
    }
    .row {

      // &:after {
      //   content: "";
      //   background-color: transparent;
      //   height: 100%;
      //   width: 100%;
      //   top: 0;
      //   left: 0;
      //   position: absolute;
      //   border: 1px solid #000000;
      // }
      // &:nth-child(2){
      //   &:after {
      //     border-top: none;
      //   }
      // }


      &.full-width {
          @include breakpoint(xxxlarge) {
            width: 60rem;
          }
      }
      

    }
    .container {
      >.row {

        >.columns {

          border: 1px solid #000000;

          // border-bottom: none;
          &:nth-child(2) {
            border-left: none;
            border-right: none;
            @media screen and (max-width: 39.9375em) {
              border-left: 1px solid #000000;
              border-right: 1px solid #000000;
            }
            &.medium-8 {
              border-right: 1px solid #000000;
              @include breakpoint(small down) {
                border-top: none;
              }
            }
          }
          .columns {
            &.medium-6 {
              &:first-child {
                border-right: 1px solid #000000;
                @include breakpoint(small down) {
                  border-bottom: 1px solid #000000;
                  border-right: none;
                }
              }
              
            }
          }
        }
        &:last-child {

          >.highlight-container {
            border-bottom: 1px solid #000000;
            @media screen and (max-width: 39.9375em) {
              &:first-child {
                border-bottom: none;
              }
              
            }
          }
          @media screen and (max-width: 39.9375em) {
            >.highlight-container {
              border-top: none;
              border-bottom: none;
            }
          }
        }
        .row:last-child {
          border-top: 1px solid #000000;
        }
        &:first-child {
        
          > .columns {
            border-bottom: none !important;
          }
        }
      }
      
    }

    .height-col-max {
      height: $portfolio-col-height+0.3vh;
    }
    .height-col-half {
      height: $portfolio-col-height/2;

    }
    .height-col-6 {
      height: $portfolio-col-height*(60/100);
    }
    .height-col-4 {
      height: $portfolio-col-height*(40/100);
    }
    .highlight-container {
      overflow: hidden;
      position: relative;
      box-sizing: border-box;
      .container, .row {
        height: 100%;
      }
      .highlight-image {
        width: auto;
        overflow: hidden;
        height: 100%;
        img {
          height: 100%;
          transition: transform 1.3s ease;
          object-fit: cover;
          display: block;
          min-width: 100%;
        }
      }
      blockquote {
        font-size: 1.5rem;
        font-family: $titles-font;
        position: relative;
        top: 50%;
        -webkit-transform: translateY(-50%);
        -ms-transform: translateY(-50%);
        transform: translateY(-50%);
        cite {
          margin-top: 1rem;
          font-size: .8rem;
          font-family: $body-font-family;
          font-style: normal;
        }
        span {
          font-family: $body-font-family;
        }
        @media screen and (max-width: 39.9375em) {
          font-size: 1rem;
        }
      }
      &:hover {
        img {
          transform: scale(1.1);
        }
      }

      $figcaption-height: 55px;
      .figcaption {
        position: absolute;
        background: #ffffff;
        width: 100%;
        height: $figcaption-height;
        bottom: -($figcaption-height);
        left: 0;
        color: $text-color;
        transition: all .4s ease;
        transition-delay: 0s;
        text-transform: uppercase;
        border-top: 1px solid #000000;
        .row {
          border-top: none !important;
          .columns {
            border: none;
          }
        }
        p {
          font-size: .8rem;
          line-height: .7rem;
        }

        &.orientation-left {
          transform-origin: left top;
          transform:rotate(-90deg) translateX(-100%);
          top: 1px;
          display: block;
          white-space: nowrap;
          left: -($figcaption-height);
          bottom: auto;
          border-bottom: 1px solid #000000;
          border-top: none;
        }
        &.orientation-right {
          transform-origin: right bottom;
          transform:rotate(-90deg) translateX(100%);
          top: auto;
          display: block;
          white-space: nowrap;
          right: -$figcaption-height;
          left: auto;
          bottom: 0;

          border-bottom: none;
        }
      }
      &:hover {

        .figcaption {
          transition: all .7s ease;
          transition-delay: 0.2s;

          bottom: 0px;
          &.orientation-left {
            left: 0px
          }
          &.orientation-right {
            // right: -($figcaption-height*2)+5;
            right: 0;
          }
        }
      }
    }
  }
}
.marquee-container {
  // position: absolute;
  // top: 0;
  // left: 0;
  // margin-top: -100px;
  @media screen and (max-width: 39.9375em) {
    margin-top: 0 !important;
    &.middle {
      transform: none !important;
      top: 15% !important;
    }
  }
}

.default {
  .video-fit-fullscreen {
    &:after {
      content: "Scroll down";
      width: 200px;
      text-align: center;
      font-family: $titles-font;
      color: #ffffff;
      font-size: .9rem;
      height: 66px;
      background-image: url(/frontend/images/scroll-down.svg);
      padding-top: .2rem;
      background-size: 28%;
      // background-color: red;
      background-repeat: no-repeat;
      background-position: bottom center;
      position: absolute;
      z-index: 2;
      bottom: 2rem;
      left: 50%;
      cursor: pointer;
      margin-left: -100px;
      @include breakpoint(small down) {
        bottom: 4rem;
      }
    }
    
  }
}
