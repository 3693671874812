
///*------------------------------------*\
//    #GENERATE ALIGN TO LEFT AND RIGHT
//\*------------------------------------*/

$aligns: (0,1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20,21,22,23,24,25,26,27,28,29,30,31,32,33,34,35,36,37,38,39,40);

@mixin generate-aligns() {
  @each $space in $aligns {
    .l-#{$space} {
      padding-left: #{$space}% !important;
    }
  }
  @each $space in $aligns {
    .r-#{$space} {
      padding-right: #{$space}% !important;
    }
  }
}


///*------------------------------------*\
//    #GENERATE MARGIN AND PADDING
//\*------------------------------------*/

$spaceamounts: (0, 5, 10, 15, 20, 25, 30, 35, 40, 45, 50, 55, 60, 65, 70, 75, 80, 85, 90, 95, 100, 110, 120, 130, 140, 150, 160, 170, 180, 190, 200, 210, 220, 230, 240);
$sides: ("left", "top", "right", "bottom");

@mixin generate-margins() {
  @each $space in $spaceamounts {
    .mb-#{$space} {
      margin-bottom: #{$space}px !important;
    }
  }
  @each $space in $spaceamounts {
    .mt-#{$space} {
      margin-top: #{$space}px !important;
    }
  }
  @each $space in $spaceamounts {
    .ml-#{$space} {
      margin-left: #{$space}px !important;
    }
  }
  @each $space in $spaceamounts {
    .mr-#{$space} {
      margin-right: #{$space}px !important;
    }
  }
}

@mixin generate-paddings() {
  @each $space in $spaceamounts {
    .pb-#{$space} {
      padding-bottom: #{$space}px !important;
    }
  }
  @each $space in $spaceamounts {
    .pt-#{$space} {
      padding-top: #{$space}px !important;
    }
  }
  @each $space in $spaceamounts {
    .pl-#{$space} {
      padding-left: #{$space}px !important;
    }
  }
  @each $space in $spaceamounts {
    .pr-#{$space} {
      padding-right: #{$space}px !important;
    }
  }
  @each $space in $spaceamounts {
    .p-#{$space} {
      padding: #{$space}px !important;
    }
  }
}

@mixin generate-borders() {
  @each $side in $sides {
    .b-#{$side} {
      border-width: 0px;
      border-#{$side}-width: 1px !important;
      border-style: solid;

    }
  }
}
