.portfolio {
  // .footer-container {
  //   display: none;
  //   top: 100%;
  //   width: 100%;
  // }
  transition: $normal-animation;
  
  .section {
    padding-right: 100px !important;
  }
  .row {
    &.full-width {
        @include breakpoint(xxxlarge) {
          width: 60rem;
        }
    }
  }
  .portfolio-list-overimages {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    // background-color: black;
    z-index: 0;

    .portfolio-list-overimages-item {
      position: fixed;
      top: 0;
      left: 0;
      height: 100%;
      width: 100%;

      // background-image: url(/frontend/contents/miyoko-home.png);
      background-repeat: no-repeat;
      background-size: cover;
      background-position: center center;
      opacity: 0;
      z-index: 0;
      // transition: $normal-animation;

      &.active {
        z-index: 1;
        opacity: 1;
      }
    }
  }
  .portfolio-list-filter {
    .filter-label {
      font-size: .8rem;
    }
    li {
      min-height: 40px;
      list-style: none;
    }
    .column-title {
      font-family: $titles-font;

      &.filter {
        a {
          display: inline-block;
          position: relative;

          color: #ffffff;
          text-decoration: none;
          &:after {
            position: absolute;
            content: "";
            width: 0%;
            height: 1px;
            background-color: #ffffff;
            left: 0;
            bottom: -3px;
            transition: $normal-animation;
          }
          &:hover, &.active {
            &:after {
              width: 100%;
            }
          }
        }
        font-size: 1.4rem;
        font-family: $content-font-bold !important;
      }
    }
    &.white {
      color: #000000;
      .column-title {
        font-family: $titles-font;

        &.filter {

          a {
            color: #000000;
            &:after {
              background-color: #000000;
            }
          }
        }
      }
    }
  }
  .portfolio-list {
    display: none;
    // background-image: url(/frontend/contents/miyoko-home.png);
    // background-repeat: no-repeat;
    // background-size: cover;
    // background-position: center center;
    // background-color: rgba(0, 0, 0, .1);
    z-index: 1;
    width: 100%;
    margin: 0;
    padding: 0;
    transition: $normal-animation;
    li {
      min-height: 40px;
      list-style: none;
      a.portfolio-list-item {
        transition: $normal-animation;
        display: block;
        min-height: 40px;
        text-decoration: none;
        color: #ffffff;

        line-height: 40px;
        &:hover {
          background-color: $main-color;
        }
        .row {
          border-bottom: 1px solid #ffffff;
          // border-top: 1px solid #ffffff;

        }
        @include breakpoint(small) {
          .title {
            font-size: .8rem !important;
            span {
              display: inline-block;
              line-height: .9rem !important;
              padding-top: .7rem;
            }
            // 
          }
        }
      }
      .columns {
        padding: 0;
      }
      &:first-child {
        a {
          .row {
            border-top: 1px solid #ffffff;
          }
        }
      }
      .title {
        font-family: $titles-font;
        text-transform: uppercase;

      }
      .categories, .date {
        line-height: 1rem;
        font-size: .8rem;
        padding-top: .5rem;
        padding-bottom: .5rem;
        span {
          display: block;
        }
      }
      .column-title {
        font-family: $titles-font;
      }
    }
    @include breakpoint(small down) {
      .mr-60 {
        margin-right: 0 !important;
      }
    }
  }
  .portfolio-grid {
    position: relative;
    display: none;
    padding-right: 140px !important;
    .row {
      margin-top: 40px;
    }
    .columns {
      // padding: 30px;
      position: relative;
      &:before {
        content: "";
        float: left;
        padding-top: 100%; 	/* initial ratio of 1:1*/
      }
      .image-container, .video-container {
        display: block;
        overflow: hidden;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        // background-image: url(/frontend/contents/miyoko-home.png);
        background-repeat: no-repeat;
        background-size: cover;
        background-position: center center;
        background-color: rgba(0, 0, 0, .1);
        width: 90%;
        height: 90%;
        transition: $normal-animation;
        opacity: 0;
        background-color: transparent;
        &:after {
          content: "";
          background-color: #000000;
          width: 100%;
          height: 100%;
          position: absolute;
          top: 0;
          left: 0;
          transition: $normal-animation;
          opacity: 0;
        }
        &:hover {
          &:after {
            opacity: 1;
          }
          h2 {
            opacity: 1;
          }
        }
        &.b-loaded {
          opacity: 1;
        }
        
      }
      .video-container {
        opacity: 1;

        video {
          width: 100%;
          // height: 100%;
        }
      }
      h2 {
        transition: $normal-animation;
        position: absolute;
        width: 90%;
        bottom: 10px;
        left: 20px;
        // transform: translate(-50%, -50%);
        text-align: left;
        text-transform: uppercase;
        color: #ffffff;
        z-index: 1;
        opacity: 0;
        font-size: 1rem;
        -webkit-text-stroke: 0px;
        span {
          font-family: $content-font;
          font-size: .7rem;
          display: block;
        }
      }
      
      @include breakpoint(medium down) {
          h2 {
            font-size: 1.2rem;
          }
      }
    }
    &.dynamic-category{
      .image-container {
        opacity: 1;
        &.b-loaded {
          opacity: 1;
        }
        &:after {
         background-color: #ffffff;
        }
        h2 {
          color: #000000;
        }
      }
    }
  }
  .portfolio-dynamic {
    position: relative;
    display: none;
    padding-right: 140px !important;
    .row {
      margin-top: 40px;
    }
    .marquee-container {
      margin-top: 0;
      // position: absolute;
      @include breakpoint(small down) {
        // margin-top: -70px !important;
        position: relative;
        top: 15%;
        // transform: translateY(-50%);
      }
    }
    // padding-top: 110px;
    .full-height {
      position: relative;
    }
  }
  @include breakpoint(medium down) {
    .section {
      padding-right: 0px !important;
    }
    .portfolio-grid, .filter-container {
      padding-right: 40px !important;
    }
    .portfolio-list-filter {
      li {
        a.filter-item {
          font-size: 1rem !important;
        }
        
        margin-bottom: 10px !important;
        margin-top: 10px !important;
      }
    }
  }
  @include breakpoint(small down) {
    .portfolio-list-filter {
      margin-left: 50px;
      li {
        a.filter-item {
          font-size: .8rem !important;
        }
      }
      .mr-60 {
        margin-right: 50px !important;
      }
      padding: 0;
    }
  }
  
}
