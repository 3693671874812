$nav-width: 190px;

.main-nav {
  overflow: hidden;
  width: 100%;
  position: fixed;
  background-color: white;
  // left: $nav-width;
  top: 0;
  min-height: $nav-width;
  z-index: 120;
  transform-origin: left top;
  transform:rotate(-90deg) translateX(-100%);
  font-family: $content-font-bold;
  margin-left: 160px;
  transition: all .8s ease-out;
  // margin-left: 60px;
  right: 100%;
  li {
    list-style: none;
    display: inline-block;
    margin-right: .5rem;
    a {
      text-transform: uppercase;
      color: $text-color;
      text-decoration: none;
      font-size: .7rem;
      transition: $normal-animation;
      &:hover, &.active {
        color: $blue;
      }
    }
  }
  &.hidden-no-animation-intro {
    transition: none;
    margin-left: 60px;
  }
  &.hidden-no-animation {
    transition: $normal-animation;
    margin-left: 60px;
  }
  .social {
    font-family: $content-font;
    li {
      a {
        text-transform: none;
      }
    }
  }
  .main-nav-contents {
    li {
      position: relative;
      &:after {
        position: absolute;
        content: "";
        width: 0%;
        background-color: $blue;
        height: 1px;
        bottom: 0;
        left: 0;
        transition: $normal-animation;
      }
      &:hover, .active {
        &:after {
          width: 100%
        }
      }
    }
  }
  .arrow-link {
    transition: $normal-animation;
    opacity: 0;
    svg {
      transition: $normal-animation;
      max-height: 95px;
      &:hover {
        transform: translateY(10px);
        .polyline, .line {
          stroke:$blue;
        }
      }
    }
  }
  .logo {
    transition: $normal-animation;
    width: 80px;
    float: right;
    transform-origin: left top;
    transform:rotate(90deg) translateY(-110%) translateX(5%);
    // position: absolute;
    margin-top: 20px;
    z-index: 1;
    // &.horizontal {
    //   opacity: 0;
    // }
    &.horizontal {
      margin-top: 0;
    }
  }

  &:after {
    transition: all .5s ease-in;
    content: "";
    background-color: white;
    width: 100%;
    height: 120px;
    top: 0;
    left: 0;
    position: absolute;
    opacity: 1;
    // display: none;
  }
  &.show-small, .hidden {
    margin-left: 60px;
    transition: all .8s ease-out;
    // &:after {
    //   display: block;
    // }
    // .logo.horizontal {
    //   opacity: 0;
    // }
    .arrow-link {
      opacity: 0;
    }
  }
  .lines-container {
    overflow: hidden;
    transition: $normal-animation;
    position: absolute;
    // background-color: red;
    top: 0;
    left: 0;
    width: 100%;
    height: 90px;
    z-index: 1;

    .lines {
      transition: $normal-animation;
      position: absolute;
      width: 10px;
      height: 50px;
      z-index: 1;
      left: 50%;
      top: 25px;
      margin-left: -5px;

      &:after, &:before {
        transition: $normal-animation;
        content: "";
        background-color: #000000;
        width: 1px;
        height: 100%;
        top: 0;
        left: 0;
        position: absolute;
      }
      &:before {
        bottom: 0;
        right: 0;
        left: auto;
        top: auto;
      }
      &:hover {
        &:after, &:before {
          background-color: $blue;
        }
        &:after {
          left: 1px;
        }
        &:before {
          right: 1px;
        }
      }
    }
  }
  // &.hidden {
  //   //margin-left: 90px;
  // }
  &.show-all {
    margin-left: 20px;
    transition: all .8s ease-out;
    .lines, .lines-container {
      height: 0;
    }
    &:after {
      height: 0;
    }
    .arrow-link {
      opacity: 1;
    }
    // .logo.vertical {
    //   opacity: 0 !important;
    //
    // }
    // .logo.horizontal {
    //   opacity: 1 !important;
    // }
  }

  @include breakpoint(medium down) {
    display: none;
  }
}

.mobile-nav {
  position: fixed;
  // top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
  z-index: 120;
  background-color: #ffffff;
  max-height: 60px;
  text-align: center;
  font-size: 1.5rem;
  transition: all .4s ease-in-out;
  bottom:0;
  .logo {
    a {
      img {
        width: 45%;
        max-width: auto !important;
      }
    }
  }
  .arrow-link {
    svg {
      max-height: 100px;
      transition: $normal-animation;
      &:hover {
        transform: translateY(10px);
        .polyline, .line {
          stroke:$blue;
        }
      }
    }
  }
  li {
    list-style: none;
    // display: inline-block;
    margin-right: 0rem;
    a {
      text-transform: uppercase;
      color: $text-color;
      text-decoration: none;

      transition: $normal-animation;
      &:hover, &.active {
        color: $blue;
      }
    }
  }

  .social {
    font-family: $content-font;
    li {
      a {
        text-transform: none;
      }
    }
  }
  .main-nav-contents {
    margin: 0;
    padding: 0;

    li {
      position: relative;
      list-style: none;
      a {
        display: inline-block;
        position: relative;
        margin-bottom: .2rem;
        &:after {
          position: absolute;
          content: "";
          width: 0%;
          background-color: $blue;
          height: 2px;
          bottom: 0;
          left: 0;
          transition: $normal-animation;
        }
        &:hover, .active {
          &:after {
            width: 100%
          }
        }
      }
    }
  }
  .language, .social {
    margin: 0;
    font-size: 1rem;
    li {
      display: inline-block;
    }
  }
  > .row:not(.lines-container) {
    margin: 0;
    position: absolute;
    top: 50%;
    left: 50%;

    -ms-transform: translateY(-50%) translateX(-50%);
    transform: translateY(-55%) translateX(-50%);
  }
  .lines-container {
    background-color: #ffffff;
    height: 60px;
    z-index: 1;
    .lines {
      transition: $normal-animation;
      position: absolute;
      width: 50px;
      height: 16px;
      z-index: 1;
      left: 50%;
      top: 20px;
      margin-left: -25px;

      &:after, &:before {
        transition: $normal-animation;
        content: "";
        background-color: #000000;
        width: 100%;
        height: 2px;
        bottom: 0;
        left: 0;
        position: absolute;
      }
      &:before {
        bottom: 1px;
        top: 1px;
        right: auto;
        left: 0;

      }
      &:hover {
        &:after, &:before {
          background-color: $blue;
        }
        &:after {
          bottom: 2px;
        }
        &:before {
          top: 2px;
        }
      }
    }
  }
  &.show-all {
    max-height: 100%;
    .lines-container {
      .lines {
        display: none;
        &:before {
          top:13px !important;
        }
        &:hover {
          &:before {
            top:11px !important;
          }
        }
      }
    }
  }
  @include breakpoint(medium up) {
    display: none;
  }
  @include breakpoint(medium down) {
    display: block;
  }
}
