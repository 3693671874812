#header {
	
	position: relative;

	overflow: hidden;

	box-sizing: border-box; // padding-top: 2rem;
	// padding-bottom: 2rem;
	// min-height: 100%;
	.layer{
		position: absolute;
		z-index: 4;
		width: 100%;
		left: 0;
		top: 43%;

		/*
		* Preventing flicker on some browsers
		* See http://stackoverflow.com/a/36671466/1081396  or issue #183
		*/
		-webkit-transform: translate3d(0,0,0);
		-ms-transform: translate3d(0,0,0);
		transform: translate3d(0,0,0);
	}
	color: $text-color;
	// background-color: $main-color;
	&.waiting {
		padding-top: 7%;
	}

	&.header {
		height: 100vh;
		min-height: 500px;
		@include breakpoint(small down) { 
			height: calc(100vh - 60px);
		}
	}

	hgroup {
		position: relative;
		z-index: 2;
		transition: all .2s ease-out;
		opacity: 0;
		h6 {
			color: transparent;
			opacity: 0;
			transition: all .2s ease-out;
			font-family: $content-font;
			margin-bottom: 0;
			font-size: 1rem;
		}
	}

	.logo {
		opacity: 0;
		transition: all .2s ease-out;
		z-index: 1;
		max-width: 20%;
		fill:transparent;
		transform: translate(.2,.2);
	}
	.header-logo-container {
		width: 100%;
		top: 0;
		position: fixed;
	}
	.video-fit-fullscreen {
		position: absolute;
		top: 0;
		left: 0;
		display: block;
		// cursor: none;
		width: 100%;
		height: 100%;
		margin: 0;
		padding: 0;

		video {
			z-index: -1;
			opacity: 0;
			position: absolute;
			top: 0;
			width: 100%;
			height: 100%;
			// float: left;
			object-fit: cover;
			@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
			/* IE10+ CSS styles go here */
				height: auto;
			}
			@supports (-ms-ime-align:auto) {
			    height: auto;
			}

		}

		.about & {
			video {
				opacity: 1 !important;
			}
		}

		a.icon-icon-scroll-down {
			position: absolute;
			bottom: .8rem;
			left: 50%;
			// background-color: red;
			font-size: 1.5rem;
			text-decoration: none;
			color: #ffffff;
			margin-left: -15px;
			transition: all .2s ease-out;
			transform: translatey(0px);
			animation: float 1s ease-out infinite;
		}
	}

	

	&.theme-white {
		hgroup {
			opacity: 1;
			h6 {
				opacity: 1;
				color: #ffffff;
			}
		}
		.logo {
			opacity: 1;
			fill:#ffffff;
		}
	}
	&.theme-blue {
		hgroup {
			opacity: 1;
			h6 {
				opacity: 1;
				color: #0000ff;
			}
		}
		.logo {
			opacity: 1;
			fill:#0000ff;
		}
	}
	&.theme-black {
		hgroup {
			opacity: 1;
			h6 {
				opacity: 1;
				color: #000000;
			}
		}
		.logo {
			opacity: 1;
			fill:#000000;
		}
	}

}
