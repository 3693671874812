#footer, .footer {
  background: #000000;
  color: #ffffff;
  min-height: 120px;
  font-size: .7rem;
  // padding-right: 100px !important;
  transition: $normal-animation;
  position: relative;
  left: 0;
  bottom: 0;
  // height: 100px;
  width: 100%;
  .row {
    min-height: 120px;
  }
  .logo {
    width: 90px;
    transition: $normal-animation;
  }
  p {
    a {
      transition: $normal-animation;
      color: #ffffff;
      text-decoration: #ffffff;
      transition: $normal-animation;
      &:hover {
        color: $blue;
      }
    }
  }
  @include breakpoint(medium down) {
    margin-bottom: 60px;
    padding-right: 0px !important;
  }
  @media screen and (max-width: 39.9375em) {
    p {
      font-size: .55rem;
      line-height: 0.7rem;
      padding: 0;
    }
    
  }

  &.white {
    background: #ffffff;
    color: $text-color;
    p {
      a {
        color: $text-color;
      }
    }
    .logo {
       filter: invert(1);
    }
  }
  img {
    visibility: visible;
  }
  @include breakpoint(small down) {
    padding-right: 10px !important;
  }
  
}
.footer-container {
  width: 100%;
  height: 120px;
}