// Bourbon stop warnings
$output-bourbon-deprecation-warnings: false;

$input-placeholder-color: #aaa;
$breakpoints: (
  small: 0,
  medium: 640px,
  large: 1024px,
  xlarge: 1200px,
  xxlarge: 1440px,
  xxxlarge: 2172px,
);
// Foundation no spaces
$grid-column-gutter: (
  small: 20px,
  medium: 30px,
  large: 30px
);

$foundation-palette: (
  primary: $main-color,
  secondary: $text-color,
  success: #3adb76,
  warning: #ffae00,
  alert: #cc4b37,
);
$global-font-size: 20px;
$body-font-family: $content-font;
$title-font-family: $titles-font;
$header-font-family: $titles-font;
$header-lineheight: 1.1;
$global-lineheight: 1.3;
$paragraph-lineheight: 1.3;
$global-weight-normal: normal;
$body-font-color: $text-color;
$body-antialiased: true;
$grid-column-align-edge: true;
$breadcrumbs-item-slash: false;
$has-tip-border-bottom: none;
$card-border: none;
$card-padding: 0;

// Menu Sidenav
$offcanvas-background: $text-color;
$accordionmenu-arrows: false;

// Accordion
$accordion-plusminus: false;
$accordion-content-padding: 1rem 0;
$accordion-content-border: none;

// Tabs
$tab-background-active: none;
$tab-color: $text-color;
$tab-active-color: $main-color;
$tab-item-padding: 0;
$tab-content-border: none;
$tab-content-padding: 0;

// Callout
$callout-margin: 0;

//Quotes
$blockquote-font-size: 26px;
$cite-pseudo-content: '';
$cite-font-size: 26px;
$cite-color: $text-color;
$blockquote-color: $text-color;
$blockquote-font-color: $text-color;
$blockquote-padding: 0;
$blockquote-border: none;
$blockquote-cite-font-size: 26px;
$blockquote-cite-font-color: $text-color;
$blockquote-cite-link-color: $main-color;
