.careers {

  .section {
    a {
      text-decoration: none;
      display: inline-block;
      position: relative;
      color: #000000;
      transition: $normal-animation;
      &:after {
        transition: $normal-animation;
        content: "";
        position: absolute;
        width: 110%;
        height: 2px;
        background-color: #000000;

        left: 0;
        bottom: -1px;
      }
      &:hover {
        color: $main-color;
        &:after {
          width: 0%;
          background-color: $main-color;
        }
      }
    }
  }
  h1 {
    font-family: $titles-font;
    font-size: 4rem;
    text-transform: uppercase;
    -webkit-text-stroke: 1px black;
    color: transparent;
    font-style: normal;
    font-weight: normal;
  }
  h4 {
    // text-transform: uppercase;
    font-family: $content-font;
  }
  @include breakpoint(medium down) {
    h1 {
      font-size: 2rem;
    }
  }

   .contacts-details {
     .row {
       min-height: 110px;
     }
   }
   .privacy-policy {
     position: fixed;
     width: 100%;
     left: 0;
     background-color: #000000;
     color: #ffffff;
     max-height: 0;
     min-height: 0;
     bottom: 0;
     z-index: 1;
     transition: all 1s ease-in-out;
     h2 {
       text-transform: none;
     }
     &.opened {

       min-height: 100%;
     }
     .close {
       display: block;
       width: 40px;
       height: 40px;
       position: absolute;
       right: 140px;
       top: 40px;
       transform: rotate(45deg);
       // background-color: $main-color;
       z-index: 2;
       @include breakpoint(medium down) {
         right: 40px;
       }
       transition: $normal-animation;
       &:after {
         content: "";
         width: 100%;
         height: 1px;
         background-color: #fff;
         display: block;
         top: 50%;
         position: absolute;
         // margin-top: -1 px;
         left: 0;
         transition: $normal-animation;
       }
       &:before {
         transition: $normal-animation;
         content: "";
         width: 1px;
         height: 100%;
         background-color: #fff;
         display: block;
         top: 0;
         position: absolute;
         // margin-left: -1px;
         left: 50%;
       }
       &:hover {
         transform: rotate(-45deg);
         &:after, &:before {
           background-color: $main-color;
         }
       }

     }
   }
}
