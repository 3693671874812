$main-color: #0b24fb; /* #ee1c24 */
$link-color: #0b24fb;
$text-color: #000;
$bkg-color-grey: #cdcac9;
$grey: #aaa;
$blue: #0000ff;
$normal-animation: all .2s ease-out;

$content-font: 'NEXTBookPan-Light', sans-serif;
$content-font-bold:  "NEXTBookPan-Regular", sans-serif;
$body-font-family: $content-font;
$titles-font: 'Trash-Medium', Helvetica, Arial, Sans-Serif;
$links: $titles-font;
$titles-italic-font: 'Trash-MediumItalic', Helvetica, Arial, Sans-Serif;
