.about {
    // @include breakpoint(xxlarge up) {
    //     scroll-snap-type: y mandatory;
    // }
   
    cite {
        color: $main-color;
        // position: absolute;
        display: inline-block;
        left: 0;
        bottom: 80px;
        font-style: normal;
        span {
            color: #ffffff;
            display: inline-block;
            margin-bottom: 1.5rem;
        }
        font-size: inherit;
        line-height: 1rem;
    }
    h2 {
        // display: inline-block;    
        position: relative;
        text-align: right;
        font-size: 1.5rem;
        font-style: normal;
        font-weight: normal;
        font-family: $content-font !important;
        -webkit-text-stroke: 0px !important;
        transform-origin: left top;
        transform:rotate(-90deg) translateX(-100%);
        border-top: 1px solid #ffffff;
    }
    a {
        &.back {
            span {
                display: inline-block;
            }
            transition: $normal-animation;
            text-align: right;
            font-size: 1.5rem;
            font-style: normal;
            font-weight: normal;
            font-family: $content-font !important;
            -webkit-text-stroke: 0px !important;
            transform-origin: left top;
            transform:rotate(-90deg) translateX(-100%);
            display: block;
            background-color: $main-color;
            color: #ffffff;
            text-decoration: none;
            position: absolute;
            top: 0;
            left: 14px;
            line-height: 42px;
            height: 0px;
            overflow: hidden;
            z-index: 1;
            &.active {
                height: 42px;
            }
        }
    }
    .color-bg-white {
        h2 { 
            border-top: 1px solid #000000;
        }

    }
    .our-core-container {
        overflow: hidden;
        .back-container {
            position: static;
        }
        .placeholder {
            opacity: 0;
        }
        .our-core-image {
            transition: all .8s ease-out;
            // transform: translatey(0px);
            // animation: float 6s ease-in-out infinite;
            // display: inline-block;
            position: relative;
            width: 100%;
            min-height: 470px;
            .overlay-image {
                position: absolute;
                display: block;
                top: 0;
                left: 0;
                z-index: 1;
                transform: translatey(0px);
                &:nth-child(2){
                    animation: floating 6s ease-in-out infinite;
                }
                &:nth-child(3) {
                    animation: float 6s ease-in-out infinite;
                }
            }
            &.active {
                 margin-left: 40px !important;
            }
            @include breakpoint(small down) {
                margin-left: -20px !important;
            }
        }
        .content-container {
            width: 100%;
            height: 100%;
            background-color:white;
            position: absolute;
            top: 0;
            left: 100vw;
            z-index: 1;
            transition: all 1s ease-out;
            overflow: hidden;
            .content-container-strip {
                position: absolute;
                transition: all .7s ease-out;
                height: 100%;
            }
            .content {
                padding-top: 10px;
                overflow: scroll;
                display: block;
                height: 100%;
                width: 100%;
                // position: absolute;
                float: left;
                background-color: white;
                h1 {
                    font-family: $titles-font;
                    text-transform: uppercase;
                    color: transparent;
                    -webkit-text-stroke: 1px $main-color;
                    // max-width: 30%;
                    font-size: 2.9rem;
                    line-height: 2.7rem;
                }
                
            }
        }
        .expertise-nav-container {
            
            position: relative;
            .expertise-nav {
                margin: 0;
                padding: 0;
                top: 0;
                width: 100%;
                position: absolute;
                li {
                    position: relative;
                    width: 100%;
                    list-style: none;
                    overflow: hidden;
                    &:before {
                        content: "";
                        position: absolute;
                        width: 100%;
                        // left: 4rem;
                        min-height: 0;
                        bottom: 0;
                        background-color: $main-color;
                        z-index: 1;
                        transition: $normal-animation;
                    }
                    &:hover {
                        &:before {
                            min-height: 100%;
                        }
                    }
                    a {
                        z-index: 1;
                        display: block;
                        // margin-left: 4rem;
                        // background-color: red;
                        height: 100%;
                        width: 100%;
                        position: absolute;
                        top: 0;
                        left: 0;
                        font-size: 1.5rem;
                        text-decoration: none;
                        transition: $normal-animation;
                        color: $text-color;
                        padding-top: 1rem;
                        padding-left: 2rem;
                        .arrow-link {
                            transition: $normal-animation;
                            position: absolute;
                            right: 1rem;
                            top: 15px;
                            width: 160px;
                            height: 50px;
                            .arrow-link {
                                transform: translateX(10px);
                                svg {
                                    transition: $normal-animation;
                                }
                            }
                        }
                        &:before {
                            content: "";
                            position: absolute;
                            width: 100%;
                            top: 0px;
                            left: 0px;
                            height: 1px;
                            background-color: #000000;
                        }
                        &:hover {
                            // background-color: $main-color;
                            color: #ffffff;
                            .arrow-link {
                                transform: translateX(10px);
                                svg {
                                    
                                    path:first-child() {
                                        fill: white;
                                    }
                                }
                            }
                        }
                        
                    }
                }
            }
            @include breakpoint(xlarge down) {
                .expertise-nav {
                    li {
                        a {
                            font-size: 1.3rem;
                            .arrow-link {
                                width: 110px;
                                height: 35px;
                            }
                        }
                    }
                }
                
            }
        }

        .contents-arrow-navigation {
            width: 100%;
            position: absolute;
            bottom: -25px;
            // background: red;
            z-index: 1;
            min-height: 0px;
            margin-left: 56px;
            transition: all .8s ease-out;
            transition-delay: 0s;
            &.active {
                min-height: 100px;
                transition-delay: 1.5s;
            }
            .arrow-link {
                transition: $normal-animation;
                position: absolute;
                right: 1rem;
                top: 15px;
                width: 160px;
                height: 50px;
                &:hover {
                    svg {
                                    
                        path:first-child() {
                            fill: $main-color;
                        }
                    }
                }
                &.previous {
                    left: 1rem;
                    transform: scaleX(-1);
                    right: auto;
                    &:hover {
                        transform: scaleX(-1) translateX(10px);
                    }
                }
                // .arrow-link {
                //     transform: translateX(10px);
                //     svg {
                //         transition: $normal-animation;
                //     }
                // }
            }
        }
    }
    .clients-list {
        padding: 0;
        margin-left: 0;
        li {
            color: #9d9896;
            list-style: none;
            padding: 0;
            span {
                color: #ffffff;
                font-family: $content-font-bold;
            }
        }
    }
    


    .team-grid {
        .team-grid-inside {
            
            // height: 90%;
            // .row {
            //     border: 1px solid white;
            //     &:nth-child(even) {
            //         border-top: 0;
            //     }
            //     .columns {
            //         &:nth-child(odd) {
            //             border-right: 1px solid white;
            //         }
            //     }
            // }
            
            
            .grid {
                display: block;
                // overflow: hidden;
                border: 1px solid white; 
                position: relative;
                height: 100%;
                width: 100%;
                cursor: pointer;
                img {
                    max-height: 670px;
                }
                .over {
                    box-sizing: border-box;
                    transition: $normal-animation;
                    position: absolute;
                    top: 0px;
                    left: 0px;
                    width: 100%;
                    height: 100%;
                    background-color: $white;
                    opacity: 0;
                    color: black;
                    font-family: $titles-font;
                    z-index: 1;
                    border: 40px solid black;
                    h4 {
                        text-transform: uppercase;
                        font-size: 3rem;
                        -webkit-text-stroke: 0px;
                        line-height: 3rem;
                    }
                    h5 {
                        font-family: $content-font;
                        -webkit-text-stroke: 0px;
                    }
                    .bio {
                        position: absolute;
                        bottom: 1rem;
                        font-size: .8rem;
                    }
                    &:after {
                        content: "";
                        width: 70px;
                        background-image: url(/frontend/images/project-next-arrow.svg);
                        height: 42px;
                        bottom: 1rem;
                        right: 1rem;
                        position: absolute;
                    }
                    &.bio-details {
                        background-color: black;
                        overflow: hidden;
                        // left: 100%;
                        // display: none; 
                        
                        box-sizing: border-box;
                        top: 0;
                        opacity: 1;
                        width: 0%;
                        height: 100%;
                        border: 0px;
                        z-index: 1;
                        &:after {
                            content: none;
                        }
                        h4, h5 {
                            color: $white;
                            width: 100%;
                            line-height: normal;
                            margin: 0;
                        }
                        h4 {
                            font-size: .9rem;
                            margin-bottom: .2rem;
                        }
                        h5 {
                            font-size: .65rem;
                        }
                        .contents {
                            min-width: 590px !important;
                            color: $white;
                            p {
                                color: $white;
                                font-family: $content-font;
                                font-size: .9rem;
                            }
                        }
                        .footer {
                            font-family: $content-font;
                            margin: 0;
                            left: 40px;
                            min-width: 590px !important;
                            position: absolute;
                            bottom: 40px;
                            min-height: auto;
                            padding: 0 !important;
                            .columns {
                                height: auto !important;
                            }
                        } 
                        a {
                            
                            color: $white;
                            &:hover {
                                text-decoration: none;
                            }
                        }
                    }
                }
                
                &:hover {
                    .over {
                        opacity: 1;
                    }
                }
               &.active {
                    .over:not(bio-details) {
                        opacity: 0;
                    }
                    .over.bio-details {
                        opacity: 1;
                        width: 100%;
                        z-index: 2;
                    }
                }
                .close {
                    top: 40px;
                    right: 40px;
                }
                
            }
            .columns {
                // background-color: red;

                &:nth-child(2) {
                    .grid {
                        border-left: 0;
                        
                    }
                    
                }
                &:nth-child(3) {
                    .grid {
                        border-top: 0;
                        
                    }
                    
                }
                &:nth-child(even) {
                    .grid.active {
                        .over.over.bio-details {
                            border-right: 1px solid $white;
                            
                            border-left: 0;
                        }
                    }
                }
                &:last-child() {
                    .grid.active {
                        .over.over.bio-details {
                            border-left: 0;
                            border-right: 1px solid $white;
                            border-bottom: 1px solid $white;
                            top: 1px;
                        }
                    }
                }
            }
            @include breakpoint(large down) {
                .columns {
                    .grid {
                        h4 {
                            font-size: 2.5rem;
                            width: 50%;
                            line-height: normal;
                        }
                    }
                }
            }
            @include breakpoint(medium down) {
                .columns {
                    .grid {
                        h4 {
                            font-size: 1.5rem;
                            width: 50%;
                            line-height: normal;
                        }
                    }
                }
            }
            @include breakpoint(small down) {
                .columns {
                    .grid {
                        border-top: 0;
                        h4 {
                            font-size: 1.5rem;
                            width: 50%;
                            line-height: normal;
                        }
                        .over {
                            border-right: 0px !important;
                            background-color: transparent;
                            color: transparent;
                            height: 20vh !important;
                            padding: 0px !important;
                            opacity: 1 !important;
                            z-index: 2;
                            border: none;
                            .bio {
                                color: #fff;
                                padding-left: 10px;
                                
                            }
                            &:after {
                                right: 1rem;
                                background-image: url(/frontend/images/arrow-right-white-big.svg);
                                background-repeat: no-repeat;
                                bottom: 1rem;
                                width: 9rem;
                                height: 20px;
                            }
                            &.bio-details {
                                display: block;
                                height: 20vh;
                                width: 100%;
                                transition: $normal-animation;
                                background-color: black;
                                z-index: 1;

                                .contents {
                                    padding: 10px !important;
                                    min-width: auto !important;
                                    .description {
                                        transition: $normal-animation;
                                        margin-top: 20vh !important;
                                        p {
                                            font-size: .7rem;
                                        }
                                    }
                                    .close {
                                        transition: $normal-animation;
                                        opacity: 0;
                                        top: 18px;
                                        right: 10px;
                                        width: 25px;
                                        height: 25px;
                                        &:after, &:before {
                                            background-color: $white !important;
                                        }
                                        &:hover {
                                            &:after, &:before {
                                                background-color: $white !important;
                                            }
                                        }
                                    }
                                    .footer {
                                        bottom: 5px;
                                        left: 0px;
                                        min-width: auto !important;
                                        text-align: left;
                                        position: static;
                                    }
                            
                                }
                                border-right: 0px !important;
                                h4 {
                                    font-size: 1.2rem;
                                    margin-bottom: .2rem;
                                    margin-top: 10px;
                                }
                                
                                h5 {
                                    font-size: .85rem;
                                }
                                
                            }
                        }
                        &.active {
                            .over {
                                display: none;
                                &.bio-details {
                                    display: block;
                                    height: 92vh !important;
                                    .close {
                                        opacity: 1;
                                    }
                                    .footer {
                                        bottom: 20px;
                                        position: absolute;
                                    }
                                    .description {
                                        margin-top: 4vh !important;
                                    }
                                }
                            }
                        }
                    }
                    &:nth-child(even) {
                        .grid {
                            border-left: 1px solid white; 
                            border-top: 0;
                            .over {
                                border-right: 0px !important;
                            }
                            
                        }
                    }
                    &:nth-child(1) {
                        .grid {
                            border-top: 1px solid white;
                        }
                    }   
                }
                > .row {
                    .img-container {
                        display: table;
                        height: 92vh;
                        padding-top: 20vh;
                        position: relative;
                        overflow: hidden;
                        text-align: left;
                    }
                    padding: 0px !important;
                    .img-wrapper {
                        display: table-row;
                        padding: 0px !important;
                        text-align: left;
                        .guide {
                            display: table-cell;
                            vertical-align: middle;
                            text-align: left;
                            width: 100%;
                        }
                        .over {
                            height: 100%;
                            top: 0;
                            left: 0;
                            // padding: 0 !important;
                            // width: 100%;
                            margin: 0 !important;
                        }
                        img {
                            
                            display: block;
                            height: 100%;
                            // position: absolute;
                            max-width: none !important;
                            max-height: none !important;
                            // margin-left: -50px;
                            margin: 0 !important;
                            // width: 100%;
                        }
                    }
                }
            }
        }
       
    }
    
    // RESPONSIVE
    @include breakpoint(medium down) {
        h2, a.back {
            transform: none;
            border-top: 0 !important;
            text-align: left !important;
        }
        a.back {
            position: absolute;
            left: 0;
            padding-left: 10px;
            bottom: 0;
            top: auto;
        }
        
        cite {
            position: static;
            padding: 0 !important;
        }
        .contents-arrow-navigation {
            padding-left: 1rem !important;
            padding-right: 1rem !important;
            margin: 0 !important;
            &.active {
                min-height: 80px !important;
            }
            
        }
        .our-core-container {
            padding: 0 !important;
            
            .expertise-nav {
                position: static;
                li {
                    &:before {
                        left: 0rem !important;
                    } 
                    a {
                        margin-left: 0rem !important;
                        .arrow-link {
                            top: 80px !important;
                        }
                    }
                }
            }
            .back-container {
                position: relative;
            }
            .expertise-nav-container {
                padding: 0;
                margin-top: 2rem;
                .content-container {
                    .content-container-strip {
                        .content {
                            padding-right: 1rem !important;
                            padding-left: 1rem !important;
                            height: 90%;
                            h1 {
                                font-size: 2.5rem;
                                margin-bottom: 30px !important;
                                line-height: 2rem;
                            }
                            div {
                                padding-right: 1rem !important;
                            }
                        }
                    }
                }
            }
            
        }
        
    }
    @include breakpoint(small down) {
        // .team-grid {
        //     padding-left: 1rem !important;
        //     padding-right: 1rem !important;
        //     .row {
        //         .columns {
        //             &:nth-child(odd) {
        //                 border-right: 0px;
        //                 border-bottom: 1px solid white;
        //             }
        //         }
        //     }
        // }
        h2{
            width: 100% !important;
        }
        #header {
            height: auto;
            min-height: auto;
            .video-fit-fullscreen {
                position: relative;
                min-height: auto;

            }
        }
        .our-core-container {
            .contents-arrow-navigation {
                position: fixed;
                bottom: -60px;
                background-color: $white;
                width: 100%;
                min-height: 80px;
            }
            .back {
                margin-bottom: 0 !important;
            }
            .expertise-nav-container {
                margin-top: 0px;
                overflow: hidden;
                .content-container {
                    overflow: hidden;
                    .content-container-strip {
                        .content {
                            padding-left: 10px  !important;
                            padding-top: 20px;
                            height: 100%;
                            padding-bottom: 30px;
                            h1 {
                                margin-bottom: 10px !important;
                                font-size: 1.6rem;
                                line-height: 1.5rem;
                            }
                            p {
                                font-size: .9rem;
                                line-height: 1.2rem;
                            }
                        }
                    }
                }
            }
        }
        .clients-container {
            p {
                font-size: .8rem;
            }
        }
        .expertise-nav {
            position: static;
            li {
                a {
                    font-size: 1.2rem !important;
                    padding-left: .5rem !important;
                }
            }
        }
    }
    @media only screen and (min-width: 1024px) and (max-height: 1366px) {
        &.section {
            padding-right: 99px; 
        }
    }

    
    
}

@keyframes float {
	0% {
		// box-shadow: 0 5px 15px 0px rgba(0,0,0,0.6);
		transform: translatey(0px);
	}
	50% {
		// box-shadow: 0 25px 15px 0px rgba(0,0,0,0.2);
		transform: translatey(-20px);
	}
	100% {
		// box-shadow: 0 5px 15px 0px rgba(0,0,0,0.6);
		transform: translatey(0px);
	}
}
@keyframes floating {
	0% {
		// box-shadow: 0 5px 15px 0px rgba(0,0,0,0.6);
		transform: translatey(0px);
	}
	50% {
		// box-shadow: 0 25px 15px 0px rgba(0,0,0,0.2);
		transform: translatey(-10px);
	}
	100% {
		// box-shadow: 0 5px 15px 0px rgba(0,0,0,0.6);
		transform: translatey(0px);
	}
}
