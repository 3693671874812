html {
   overflow-x: hidden !important;
   position: relative;
   min-height: 100%;
}
body {
    font-family: $body-font-family;
    a {
        text-decoration: underline;
    }
    overflow-x: hidden !important;
    -webkit-font-smoothing: antialiased;
    // margin: 0 0 120px;
    // opacity: 0;
    // animation: fadeInPage 1s forwards;
    // animation-delay: .5s;
    transition: $normal-animation;
}

a {
    text-decoration: underline;

    color: $link-color;

    &.warning-link {
        font-family: $body-font-family;
        font-size: .7rem;

        text-decoration: underline;
        text-transform: uppercase;
    }
}
button {
    outline: none !important;
    &:focus,
    &:hover {
        outline: none !important;
    }
}
hr {
    border-color: #000;
}

.container,
.row {
    position: relative;
}

.row.expanded {
    margin: 0 -30px;
}

.b-lazy {
    // @include transition(opacity .15s ease-in);
    @include backface-visibility(hidden);
    max-width: 100%;

    -webkit-transition: opacity 600ms ease-out;
    -moz-transition: opacity 600ms ease-out;
    -o-transition: opacity 600ms ease-out;
    transition: opacity 600ms ease-out;
    // max-width: 100%;
    opacity: 0;

    will-change: opacity;
    &.b-loaded {
      opacity: 1;
    }
}

.img-responsive {
  display: block;
  width: 100%;
}

@keyframes fade-in {
    from {
      transform: translateY(10%);
      opacity: 0;
    }
    to {
      transform: translateY(0);
      opacity: 1;
    }
}

.element-animate {
  animation: fade-in .3s;
  transform: translateX(0px);
}

.animation-do {
  transition: $normal-animation;
  opacity: 0;
  transform: translateX(-10px) !important;
}
.reveal-overlay {
    background-color: rgba(0, 0, 0, 0);
    .reveal {
        outline: none;
        background-color: rgba(19, 44, 58, 0.9);
        color: #ffffff;
        border-color: transparent;
        text-align: center;
        top: 50% !important;
        margin-top: -115px;

        padding: 3rem 2rem;

        @include breakpoint(small down) {
            top: auto !important;
            margin-top: auto !important;
        }
        p {
            font-size: rem-calc(18);
            margin-bottom: 1rem;
            margin-top: 1rem;
        }
        button {
            color: #ffffff;
            font-family: $body-font-family;
            font-size: rem-calc(18);
            text-decoration: underline;
            cursor: pointer;
        }
        h1 {
            text-transform: uppercase;
            font-family: $body-font-family;
            font-size: rem-calc(18);
            @include breakpoint(small down) {
                font-weight: 600 !important;
            }
        }
        a {
            font-family: $body-font-family;
            color: #ffffff;
            font-size: rem-calc(18);
            background-color: transparent !important;
            text-transform: uppercase;
            span {
                background-color: transparent !important;
            }
            &.lang-switch {
                position: absolute;
                top: 20px;
                left: 20px;
            }
            @include breakpoint(small down) {
                font-weight: 600 !important;
            }
        }

    }
}

// .generic-content-container {
//     .container {
//         .row {
//             // background-color: blue;
//         }
//     }
// }

.fixed-back-to-top {
    position: fixed;
    right: 7%;
    top: 50%;
    margin-top: 30px;
    width: 140px;
    height: 30px;
    text-align: right;
    display: none;


    a {
        color: black;
    }
    span {
        color: black;
    }

}

h1, h2, h3, h4, h5, h6 {
    &.italic {
        font-family: $titles-italic-font;
    }
    font-style: normal;
    font-weight: normal;
}
.bold {
  font-family: $content-font-bold;
}
video::-webkit-media-controls {
    display:none !important;
    -webkit-appearance: none;
}
*::-webkit-media-controls-panel {
  display: none!important;
  -webkit-appearance: none;
}

*::--webkit-media-controls-play-button {
  display: none!important;
  -webkit-appearance: none;
}

*::-webkit-media-controls-start-playback-button {
  display: none!important;
  -webkit-appearance: none;
}
.full-width {
   width: 100%;
   margin-left: auto;
   margin-right: auto;
   max-width: initial;
}
.arrow-link {
  .line {
    fill:none;stroke:#000000;stroke-miterlimit:10;
    transition: all .2s ease-out;
  }
  .polyline {
    fill:none;stroke:#000000;stroke-width:1.0811;stroke-miterlimit:10;
    transition: all .2s ease-out;
  }
  transition: all .2s ease-out;
  max-width: 240px;
  &:hover {
    transform: translateX(10px);
    .polyline, .line {
      stroke:$blue;
    }
  }
}
.close {
  display: block;
  width: 40px;
  height: 40px;
  position: absolute;
  right: 15px;
  top: 15px;
  transform: rotate(45deg);
  // background-color: $main-color;
  transition: $normal-animation;
  &:after {
    content: "";
    width: 100%;
    height: 1px;
    background-color: #fff;
    display: block;
    top: 50%;
    position: absolute;
    // margin-top: -1 px;
    left: 0;
    transition: $normal-animation;
  }
  &:before {
    transition: $normal-animation;
    content: "";
    width: 1px;
    height: 100%;
    background-color: #fff;
    display: block;
    top: 0;
    position: absolute;
    // margin-left: -1px;
    left: 50%;
  }
  &:hover {
    &:after, &::before {
      background-color:$main-color; 
    }
  }

}
.relative {
  position: relative;
}
.middle {
  position: relative;
  top: 50%;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
}
.middle-absolute {
  position: absolute;
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  top: 50%;
  left: 50%;
}
.scaleable-wrapper {
  transform-origin: top left;
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  background: red;
}

.iScrollVerticalScrollbar {
  opacity: 0 !important;
}
// @keyframes fadeOutPage {
//     from { opacity: 1 }
//       to { opacity: 0 }
// }
//
// @keyframes fadeInPage {
//     from { opacity: 0 }
//       to { opacity: 1 }
// }
.marquee-container {
  .marquee {
    position: relative;
    width: 100vw;
    max-width: 100%;
    height: 100px;
    overflow: hidden;

    .track {
      position: absolute;
      white-space: nowrap;
      will-change: transform;
      $animation-time: 40s;
      &.left {
        animation: marqueeleft $animation-time linear infinite;
      }
      &.right {
        animation: marqueeright $animation-time linear infinite;
      }
      &:hover, &.paused {
          animation-play-state: paused;
      }

    }
    @keyframes marqueeleft {
      from { transform: translateX(0); }
      to { transform: translateX(-50%); }
    }
    @keyframes marqueeright {
      to { transform: translateX(0); }
      from { transform: translateX(-50%); }
    }
    a.marquee-move-anchor {
      text-decoration: none;
      font-family: $titles-font !important;
      font-size: 4rem;
      text-transform: uppercase;
      -webkit-text-stroke: 1px rgba(255, 255, 255, .6);
      color: transparent;
      font-style: normal;
      font-weight: normal;
      transition: all .2s ease-out;
      span {
        transition: all .2s ease-out;
        display: inline-block;
        width: 15px;
        height: 15px;
        margin-left: 15px;
        margin-right: 15px;
        margin-bottom: 10px;
        // border-radius: 50%;
        background-color: transparent;
        border: 1px solid $blue;
        vertical-align: middle;

      }
      &:hover {
        -webkit-text-stroke: 1px rgba(255, 255, 255, 1);

        span {
            border: 1px solid #ffffff;
        }
      }
    }
    @media screen and (max-width: 39.9375em) {
      height: 60px !important;
      a.marquee-move-anchor {
        font-size: 3rem !important;
      }
    }

    @include breakpoint(medium down) { 
      a.marquee-move-anchor {
        font-size: 2rem !important;
      }
    }

  }
  @include breakpoint(medium down) {
    width: 100%;
    .row {
      margin: 0;
      padding: 0;
    }
  }
}

.video-controls-container {
  position: absolute;
  z-index: 2;
  top: 50%;
  left: 50%;

  width: 100px;
  height: 100px;
  margin-top: -50px;
  margin-left: -50px;

  a.icon-icon-play, a.icon-icon-pause {
    
    transition: opacity .2s ease-out;
    text-decoration: none;
    display: block;
    opacity: 1;
    color: #fff;
    &:hover {
      opacity: .1;
    }
    width: 100%;
    height: 100%;
    background-image: url(/frontend/images/videoplay.svg);
    background-size: 100% 100%;
    background-repeat: no-repeat;
  }
  a.icon-icon-pause { 
    background-image: url(/frontend/images/videopause.svg);
  }
  &.home {
    display: none;
  }
  @include breakpoint(small down) {
    width: 44px;
    height: 44px;
    margin-top: -22px;
    margin-left: -22px;

    a {
      font-size: 2.2rem;
    }
    &.home {
      display: block;
    }
  }

}